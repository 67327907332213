.bearList{
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  //height: 20%;

  position: absolute;
  bottom: -3%;

  img{
    width: 100%;
    height: 100%;
  }
}