.controls{
  width: 90%;
  height: 12%;
  margin: 0 auto;
  padding: 1% 0 0 0;
  display: flex;
  justify-content: space-between;

  div{
    width: 25%;
  }

  button {
    height: auto;
    width: 50%;

    img{
      height: auto;
      width: 80%;
    }
  }

  a {
    height: auto;
    width: 50%;
    display: inline-block;

    img{
      height: auto;
      width: 80%;
    }
  }

  .btn-sound{
    img{
      width: 86%;
    }
  }

  //ARCHITECTURE WORKAROUND (see below)
  //This button should be hidden by default and only revealed when in the actual pages of a book that have narration (not the covers)
  .btn-narrate{
    display: none;
  }
}

.controls-home{
  .controls-right{
    display: flex;
    flex-direction: row-reverse;
  }

  a{
    display: none;
  }
}

//ARCHITECTURE WORKAROUND
//the InteractiveBookScreen component (whenever it updates) adds page number to the class of the 'game' element
//this is used to show the narration replay button only on relavant pages
.game {
  &.p1, &.p3, &.p5, &.p7, &.p9, &.p11, &.p13, &.p15{
    .btn-narrate{
      display: inline-block;
    }
  }
}

// .btn-sound{
//   position: absolute;
//   height: 70px;
//   width: 70px;
//   top: 2%;
//   right: 2%;
// }