.box{
  position: absolute;

  img{
    top:0px;
    left:0px;
    opacity: 0;
  }
}

.transparent{
  opacity: 0;
}

.clickable-placeholder{
}

.animated {
  -webkit-animation-duration: 2.5s;
  animation-duration: 2.5s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes fade {
  0%{
    opacity: 0;
  }
  25%{
    opacity: 1;
  }
  50%{
    opacity: 0;
  }
  75%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}

@-webkit-keyframes fade {
  0%{
    opacity: 0;
  }
  25%{
    opacity: 1;
  }
  50%{
    opacity: 0;
  }
  75%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}

.fade {
  -webkit-animation-name: fade;
  animation-name: fade;
}

@keyframes flip {
  0%{
    transform: scaleX(1);
    opacity: 0;
  }
  5%{
    opacity: 1;
  }
  24%{
    transform: scaleX(1);
  }
  25%{
    transform: scaleX(-1);
  }
  49%{
    transform: scaleX(-1);
  }
  50%{
    transform: scaleX(1);
  }
  74%{
    transform: scaleX(1);
  }
  75%{
    transform: scaleX(-1);
  }
  95%{
    opacity: 1;
  }
  99%{
    transform: scaleX(-1);
  }
  100%{
    transform: scaleX(1);
    opacity: 0;
  }
}

@-webkit-keyframes flip {
  0%{
    -webkit-transform: scaleX(1);
    opacity: 0;
  }
  5%{
    opacity: 1;
  }
  24%{
    -webkit-transform: scaleX(1);
  }
  25%{
    -webkit-transform: scaleX(-1);
  }
  49%{
    -webkit-transform: scaleX(-1);
  }
  50%{
    -webkit-transform: scaleX(1);
  }
  74%{
    -webkit-transform: scaleX(1);
  }
  75%{
    -webkit-transform: scaleX(-1);
  }
  95%{
    opacity: 1;
  }
  99%{
    -webkit-transform: scaleX(-1);
  }
  100%{
    -webkit-transform: scaleX(1);
    opacity: 0;
  }
}

.flip {
  -webkit-animation-name: flip;
  animation-name: flip;
}

@keyframes fly {
  0%{
    transform: scaleX(1);
    opacity: 1;
    top: 39%;
    left: 56%;
  }
  25%{
    transform: scaleX(-1);
  }
  50%{
    transform: scaleX(1);
    top: 41%;
    left: 44%;
  }
  75%{
    transform: scaleX(-1);
  }
  95%{
    opacity: 1;
  }
  100%{
    transform: scaleX(1);
    opacity: 0;
    top: 39%;
    left: 56%;
  }
}

@-webkit-keyframes fly {
  0%{
    -webkit-transform: scaleX(1);
    opacity: 1;
    top: 39%;
    left: 56%;
  }
  25%{
    -webkit-transform: scaleX(-1);
  }
  50%{
    -webkit-transform: scaleX(1);
    top: 41%;
    left: 44%;
  }
  75%{
    -webkit-transform: scaleX(-1);
  }
  95%{
    opacity: 1;
  }
  100%{
    -webkit-transform: scaleX(1);
    opacity: 0;
    top: 39%;
    left: 56%;
  }
}

.fly {
  -webkit-animation-name: fly;
  animation-name: fly;
}

@keyframes hide {
  0%{
    opacity: 1;
  }
  1%{
    opacity: 0;
  }
  99%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

@-webkit-keyframes hide {
  0%{
    opacity: 1;
  }
  1%{
    opacity: 0;
  }
  99%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

.hide {
  -webkit-animation-name: hide;
  animation-name: hide;
}

//This animation reveals/hides the hidden second frame to animate two frame animations
@keyframes twoFrame {
  0%{
    opacity: 0;
  }
  1%{
    opacity: 1;
  }
  19%{
    opacity: 1;
  }
  20%{
    opacity: 0;
  }
  39%{
    opacity: 0;
  }
  40%{
    opacity: 1;
  }
  59%{
    opacity: 1;
  }
  60%{
    opacity: 0;
  }
  79%{
    opacity: 0;
  }
  80%{
    opacity: 1;
  }
  99%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}

@-webkit-keyframes twoFrame {
  0%{
    opacity: 0;
  }
  1%{
    opacity: 1;
  }
  19%{
    opacity: 1;
  }
  20%{
    opacity: 0;
  }
  39%{
    opacity: 0;
  }
  40%{
    opacity: 1;
  }
  59%{
    opacity: 1;
  }
  60%{
    opacity: 0;
  }
  79%{
    opacity: 0;
  }
  80%{
    opacity: 1;
  }
  99%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}

.twoFrame {
  -webkit-animation-name: twoFrame;
  animation-name: twoFrame;
}

//Animation to hide and reveal the placeholder image in time with the second frame
@keyframes twoFrame_ph {
  0%{
    opacity: 1;
  }
  1%{
    opacity: 0;
  }
  19%{
    opacity: 0;
  }
  20%{
    opacity: 1;
  }
  39%{
    opacity: 1;
  }
  40%{
    opacity: 0;
  }
  59%{
    opacity: 0;
  }
  60%{
    opacity: 1;
  }
  79%{
    opacity: 1;
  }
  80%{
    opacity: 0;
  }
  99%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

@-webkit-keyframes twoFrame_ph {
  0%{
    opacity: 1;
  }
  1%{
    opacity: 0;
  }
  19%{
    opacity: 0;
  }
  20%{
    opacity: 1;
  }
  39%{
    opacity: 1;
  }
  40%{
    opacity: 0;
  }
  59%{
    opacity: 0;
  }
  60%{
    opacity: 1;
  }
  79%{
    opacity: 1;
  }
  80%{
    opacity: 0;
  }
  99%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

.twoFrame_ph {
  -webkit-animation-name: twoFrame_ph;
  animation-name: twoFrame_ph;
}

//Clip paths to make certain buttons easier to press
#b0_p11_clickable_c{
  clip-path: polygon(65% 3%, 85% 4%, 96% 56%, 60% 100%, 36% 96%, 30% 66%, 11% 77%, 4% 65%, 41% 40%);
}