.navigationBook{
  position: absolute;
  top: 30%;
  width: 100%;

  img{
    width: 100%;
  }

  .navButtons{
    display: flex;
    justify-content: space-between;

    a{
      width:8%;
      img{
        width: 100%;
      }
    }
  }
}

.btn-nav{
  display: flex;
  height: 90px;
  width: 5%;

  /* Default unhovered scale is 85%. Transitioning to other transforms
  will take 100ms and use the ease-in-out curve*/
  transform: scale(.95);
  transition: all 100ms ease-in-out;

  background-size: contain;

  &:hover {
    transform: scale(1.30);
    z-index: 5;
  }

  //Fix for hover getting stuck on touchscreens
  //https://medium.com/@mezoistvan/finally-a-css-only-solution-to-hover-on-touchscreens-c498af39c31c
  @media(hover: none), (pointer: coarse) {
    &:hover {
      transform: none;
    }
  }
}

.nav-left {
  // Set background image
  background-image: url("../../../Images/Icons/arrowPrev_btn.png");
  background-repeat: no-repeat;
}

.nav-right {
  // Set background image properties
  background-image: url("../../../Images/Icons/arrowNext_btn.png");
  background-repeat: no-repeat;
  //compensate for image which is off center
  //position: relative;
  //left: 10px;
}

//animation applied to navigation button on front cover to draw attention
.nav-animated {
  -webkit-animation: breathing 2s ease-out infinite normal;
  animation: breathing 2s ease-out infinite normal;

  &:hover{
    -webkit-animation: none;
    animation: none;
  }
}

@-webkit-keyframes breathing {
  0% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }

  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }
}

@keyframes breathing {
  0% {
    -webkit-transform: scale(0.7);
    -ms-transform: scale(0.7);
    transform: scale(0.7);
  }

  50% {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(0.7);
    -ms-transform: scale(0.7);
    transform: scale(0.7);
  }
}