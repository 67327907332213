@import "../../globals";

.titleScreen{
  height: 100%;
  position: relative;
}

.title-main{
  display: flex;
  align-items: center;
  padding: 3% 0 0 0;

  img{
    width: 41%;
  }

  div{
    width: 59%;
  }

  h1{
    font-size: 72px;
  }

  @media screen and (max-width: $screen-s-max), (max-height: $screen-s-vmax) {
    h1{
      font-size: 48px;
    }
  }

  @media screen and (max-width: $screen-xs-max), (max-height: $screen-xs-vmax)  {
    h1{
      font-size: 36px;
    }
  }
}

.btn-start{
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translateX(-50%);
  width: 23%;

  img{
    width: 80%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  .btn-shadow{
    position: absolute;
  }
}

.logos{
  position: absolute;
  bottom: -3px;
  width: 100%;

  img{
    width: 100%;
  }
}