@import "../../../globals";

.word {
  display: block;
  position: relative;
  white-space: nowrap;
  font-size: 26px;
  line-height: 120%;

  @media screen and (max-width: $screen-s-max), (max-height: $screen-s-vmax) {
    font-size: 20px;
  }

  @media screen and (max-width: $screen-xs-max), (max-height: $screen-xs-vmax) {
    font-size: 16px;
  }

  @media screen and (max-width: $screen-xxs-max), (max-height: $screen-xxs-vmax) {
    font-size: 12px;
  }

  @media screen and (max-width: $screen-xxxs-max), (max-height: $screen-xxxs-vmax) {
    font-size: 8px;
  }
}

.word-overlay {
  display: block;
  position: absolute;
  top:0;
  left:0;
}

//Book specific adjustments.
//The Trip
//Increase contrast against various backgrounds
.the_trip{
  .word{
    text-shadow: 1px 1px 3px white, -1px 1px 3px white, 1px -1px 3px white, -1px -1px 3px white; 
  }
}

//Where's My Stick
.wms{
  .word{
    font-size: 16px;
  }

  @media screen and (max-height: 720px) {
    .word{
      font-size: 14px;
    }
  }

  @media screen and (max-width: $screen-s-max), (max-height: $screen-s-vmax) {
    .word{
      font-size: 12px;
    }
  }

  @media screen and (max-width: $screen-xs-max), (max-height: $screen-xs-vmax) {
    .word{
      font-size: 8px;
    }
  }

  @media screen and (max-width: $screen-xxs-max), (max-height: $screen-xxs-vmax) {
    .word{
      font-size: 6px;
    }
  }

  @media screen and (max-width: $screen-xxxs-max), (max-height: $screen-xxxs-vmax) {
    .word{
      font-size: 4px;
    }
  }

  @media screen and (max-height: $screen-xxxxs-vmax) {
    .word{
      font-size: 4px;
    }
  }
}

.purple{
  .word{
    font-size: 20px;
  }

  @media screen and (max-width: $screen-s-max), (max-height: $screen-s-vmax) {
    .word{
      font-size: 14px;
    }
  }

  @media screen and (max-width: $screen-xs-max), (max-height: $screen-xs-vmax) {
    .word{
      font-size: 12px;
    }
  }

  @media screen and (max-width: $screen-xxs-max), (max-height: $screen-xxs-vmax) {
    .word{
      font-size: 10px;
    }
  }

  @media screen and (max-width: $screen-xxxs-max), (max-height: $screen-xxxs-vmax) {
    .word{
      font-size: 8px;
    }
  }

  @media screen and (max-width: $screen-xxxxs-max), (max-height: $screen-xxxxs-vmax) {
    .word{
      font-size: 6px;
    }
  }
}

.karaoke-text {
  //color: lightblue;
}

.karaoke-active-overlay {
  position: absolute;
  left: 0;
  top: 0;
  color: #A756B3;
  overflow: hidden;
  animation-name: run-text;
  animation-timing-function: linear;
  animation-duration: 1s;
}

@keyframes run-text {
  from { width: 0% }
  to { width: 100% }
}

.karaoke-finished-overlay {
  color: #A756B3;
}
