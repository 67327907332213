.interactive-book-screen {
  position: absolute;
  top: 12%;
  left: 0;
  right: 0;
  justify-content: space-evenly;
  height: 88%;

  h1{
    font-family: Nunito;
    font-size: 5em;
    -webkit-text-stroke-width: 0px;
  }
}