.bookIconContainer{
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 1px 6px;

  img{
    // Define the size of this image
    width: 100%;
    height: 100%;

    // Center this image
    margin: auto;
  }

  button{
    // Define the size of this button
    width: 100%;
    height: 100%;
  }
}