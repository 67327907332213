.book-single .page {
    width: 100%;
}

.book-double .page {
    width: 50%; // Half of the book
}

.page {
    height: 100%;
    margin: auto 0;
    align-content: center;
    background-color: whitesmoke;
    position: relative;

    font-size: 2vmin;

    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);

    p{
        z-index: 1;
        display: inline;
        position: relative;
    }

    img{
        width: 100%;
        height: 100%;

        position: absolute;
    }
}

.pageNumber{
    position: absolute;
    bottom: 5px;
}

.rightPageNumber {
    right: 10px;
}

.leftPageNumber{
    left: 10px;
}

// Karaoke text
.text-container {
    position: relative;
    height: 100%;
    width: 100%;

    div{
        display: inline-block;
        position: absolute;
    }
}

.text-main {
    position: relative;
    white-space: nowrap;
    color: lightblue;
    text-shadow: 0 0 3px rgba(0,0,0,1); 
}