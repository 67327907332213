body {
  // Both headers share the same drop shadow and text align
  font-family: ABeeZee;

  h1, h2 {
    //text-shadow: 0px 3.5px 8.5px rgba(0, 0, 0, 0.55);
    text-align: center;
  }

  h1 {
    font-family: Jollygood Proper Regular;
    font-size: 9vmin;
    color: #0066CC;
  }

  h2 {
    
    font-size: 7vmin;
    color: white;
  }

  button {
    background-color: transparent;
    border:none;
  }

  .hidden{
    display: none;
  }
}