button{
  padding: 0;
}

button.btn-primary{
  // Make the cursor turn into a pointer indicating that this can be clicked
  cursor: pointer;

  // Change the font inside this button
  font-family: Nunito;
  font-size: 4.5vmin;
  font-weight: bold;
  color: #FFFFFF;

  // Change the look of this button
  border-radius: 40px;
  background: #FFCF00;
  border: 2px solid #FFFFFF;

  // Enable animations (Use for button hover)
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

// When this button is being hovered, lets give it a box shadow which will animate cause of our transition duration
button.btn-primary:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

// .btn{
//   img{
//     position: relative;
//     top: 0;
//     left: 0;
//     height: 70px;
//     width: 70px;
//   }
// }

.btn-close{
  position: absolute;
}