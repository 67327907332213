.book {

    width: 90%;
    height: 65%;
    margin: 0 auto;
    position: relative;
    top: 2%;

    .bookContent {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: 100%
    }

    .book-double {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        border-radius: 4px;
        background: #A8F0F4;
        /* 24 dp shadow */
        box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.25), 0px 24px 24px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        // border-bottom: 2px solid rgba(0, 0, 0, 0.25);
        // border-left: 2px solid rgba(177, 177, 177, 0.25);
        // border-top: 2px solid rgba(255, 255, 255, 0.25);
        // border-right: 2px solid rgba(78, 78, 78, 0.25);

        .bookContent {
            width: 98%;
            height: 98%;
        }

        .inner-spine{
            height: 1%;
            width: 3%;
            position: absolute;
            background: rgba(0, 0, 0, 0.25);

            &.lower{
                bottom: 0;
            }

            &.upper{
                top: 0;
            }
        }

        .inner-shadow{
            width: 1px;
            height: 98%;
            position: absolute;
            left: 50%;
            z-index: 3;
            background-color: rgb(54, 54, 54);
            -webkit-box-shadow: 6px 0px 22px 2px #000000, -6px 0px 22px 2px #000000; 
            box-shadow: 6px 0px 22px 2px #000000, -6px 0px 22px 2px #000000, 0px 0px 9px 2px #000000;
        }

        &.the_trip{
            background: #3b8195;
        }

        &.qsd{
            background: #A8F0F4;
        }

        &.wms{
            background: #d6463d;
        }

        &.purple{
            background: #67528d;
        }
    }

    .book-single{
        height: 100%;
        width: 60%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }

    .front {
        flex-direction: row-reverse;
    }

    .back {
        flex-direction: row;
    }
}
