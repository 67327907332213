@import "../../globals";

.captions{
  position: absolute;
  bottom: 22%;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px;
  width: 40%;
  font-family: Open Sans;
  font-size: 20px;
  line-height: 130%;
  color: white;
  background: rgba(0,0,0,.4);
  z-index: 15;

  @media screen and (max-width: $screen-s-max), (max-height: $screen-s-vmax) {
    width: 60%;
    font-size: 14px;
  }

  @media screen and (max-width: $screen-xs-max), (max-height: $screen-xs-vmax) {
    width: 80%;
    padding: 10px;
    font-size: 10px;
  }
}