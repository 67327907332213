@import "../../globals";

.frame{
};

.game {
  width: 100vw;
  height: 75vw;
  max-height: 891px;
  max-width: 1188px; //(4/3 * 100)
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  @media (min-aspect-ratio: 4/3) {
    width: 133vh;
    height: 100vh;
    width: calc(var(--vh, 1vh) * 133);
    height: calc(var(--vh, 1vh) * 100);
  }
}

//address bar on chrome mobile is included in the screen size
//this compensates for that
// @supports (-webkit-appearance:none) {
//   .game {
//     width: calc(100vw - 74px);
//     height: calc(75vw - 56px);

//     @media (min-aspect-ratio: 4/3) {
//       width: calc(133vh - 74px);
//       height: calc(100vh - 56px);
//     }
//   }
// }

.background{
  position: fixed;
  z-index: -1;
  width: 100%;
  margin-top: 0%
}

.no-display{
  display: none !important; 
}

.no-visibility{
  visibility: hidden;
}