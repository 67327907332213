.bear {
  position: relative;
  transform: scale(.8);
  overflow: visible;

  .bearProfile {
  }

  &.melody{
    img{
      transform: translateY(-2px);
    }
  }

  &.scout{
    img{
      transform: translateY(-13px);
    }
  }
}