.cover-paw{
    position: absolute;
    width: 100%;
    height: 100%;

    //Doesn't affect style but needed for IE
    top: 0;
    left: 0;

    button {
        width: 100%;
        height: 100%;

        img{
            width: 100%;
            height: 100%;
        }
    }
}

.fadeout{
    -webkit-animation-duration: 2.5s;
    animation-duration: 2.5s;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeout;
    animation-name: fadeout;
}

@keyframes fadeout {
    0%{
      opacity: 1;
    }
    100%{
      opacity: 0;
    }
}
  
  @-webkit-keyframes fadeout {
    0%{
      opacity: 1;
    }
    100%{
      opacity: 0;
    }
}