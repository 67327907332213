@import "../../globals";

.modal__overlay{
    width: 100vw;
    height: 75vw;
    max-height: 891px;
    max-width: 1188px;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);

    &.video__overlay{
        background-color: rgba(0, 0, 0, 0.9);
    }

    @media (min-aspect-ratio: 4/3) {
        width: 133vh;
        height: 100vh;
        width: calc(var(--vh, 1vh) * 133);
        height: calc(var(--vh, 1vh) * 100);
      }
}

//address bar on chrome mobile is included in the screen size
//this compensates for that
// @supports (-webkit-appearance:none) {
//     .modal__overlay {
//         width: calc(100vw - 74px);
//         height: calc(75vw - 56px);

//         @media (min-aspect-ratio: 4/3) {
//             width: calc(133vh - 74px);
//             height: calc(100vh - 56px);
//         }
//     }
// }

.modal__custom{
    position: absolute;
    top: 50%;
    left:50%;
    transform: translate(-50%,-50%);
    margin:auto;
    height: 70%;
    width: 70%;
    background: transparent;
    // WebkitOverflowScrolling: touch;
    // borderRadius: 4px;
    outline: none;
    z-index: 10;

    .modal{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }

    &.video__custom{
        height: 100%;
        width: 100%;
    }
}

.modal__confirm{
    height: 80%;
    width: 80%;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    a{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-around;

        img{
            width: 100%;
        }
    }

    .btn-close{
        top: -13%;
        right: -10%;
        width: 20%;

        img{
            width: 100%;
        }
    }
}

.modal__video{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    video{
        width: 100%;
    }

    //Targeting IE
    @media screen and (-ms-high-contrast: none) {
        height: auto;
    }

    //Move safari captions up so not off bottom of screen
    video::-webkit-media-text-track-display {
        top: -7% !important;
    }
}

.modal__options{
    height: 75%;
    width: 80%;
    background-image: url("../../Images/Icons/options_bg.png");
    background-size: cover;
    color: white;
    
    h2{
        font-size: 41px;
        line-height: 120%;
        font-weight: 600;
        margin: 7% 0;
    }

    h3{
        text-align: center;
        font-size: 24px;
        line-height: 120%;
        margin: 0 0 14px 0;
    }

    .menu-content{
        width: 80%;
        position: absolute;
        top: 15%;
        left: 50%;
        transform: translateX(-50%);

        .options-group{
            display: flex;
            justify-content: space-around;

            .option-container{
                width: 50%;
                display: flex;
                flex-direction: column;
                align-items: center;

                .btn{
                    width: 80%;

                    img{
                        width: 100%;
                    }
                }
            }
        }
    }

    .btn-close{
        width: 15%;
        padding: 0;
        top: 7%;
        right: -2%;

        img{
            width: 100%;
        }
    }

    @media screen and (max-height: $screen-m-vmax) {
        h2{
            font-size: 28px;
        }
        h3{
            font-size: 16px;
        }
    }

    @media screen and (max-width: $screen-s-max), (max-height: $screen-s-vmax) {
        h2{
            font-size: 28px;
        }
        h3{
            font-size: 14px;
        }
    }

    @media screen and (max-width: $screen-xs-max), (max-height: $screen-xs-vmax) {
        h2{
            font-size: 20px;
        }
        h3{
            font-size: 12px;
            margin: 0 0 7px 0;
        }
    }

    @media screen and (max-width: $screen-xxs-max), (max-height: $screen-xxs-vmax) {
        h2{
            font-size: 14px;
        }
        h3{
            font-size: 10px;
        }
    }

    @media screen and (max-width: $screen-xxxs-max), (max-height: $screen-xxxs-vmax) {
        h2{
            font-size: 10px;
        }
        h3{
            font-size: 8px;
        }
    }
}