@font-face {
  font-family: 'JollyGood Proper Regular';
  font-style: normal;
  font-weight: normal;
  src: local('JollyGood Proper Regular'), url('./Fonts/JollyGoodProper-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./Fonts/open-sans-v17-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
       url('./Fonts/open-sans-v17-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Fonts/open-sans-v17-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./Fonts/open-sans-v17-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./Fonts/open-sans-v17-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Fonts/open-sans-v17-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* abeezee-regular - latin */
@font-face {
  font-family: 'ABeeZee';
  font-style: normal;
  font-weight: 400;
  src: url('./Fonts/abeezee-v13-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('ABeeZee Regular'), local('ABeeZee-Regular'),
       url('./Fonts/abeezee-v13-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Fonts/abeezee-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./Fonts/abeezee-v13-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./Fonts/abeezee-v13-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Fonts/abeezee-v13-latin-regular.svg#ABeeZee') format('svg'); /* Legacy iOS */
}