.splashScreen{
  position: absolute;
  bottom: 0;
  height: 85%;
  width: 100%;

  .header {
    margin-top: 5%;
  }

  .iconWrapper{
    position: relative;
    width: 100%;
    height: 100%;

    .bookIconContainer{
      width: 11%;
    }
  }

  .searching-melody{
    position: absolute;
    top: 45%;
    left: 32.1%;
    height: 47%;
  }
}